/* eslint-disable import/no-anonymous-default-export */
import { API_BASE, JSON_HEADER } from '../constants';

// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const getParamNValue = (data, code) => {
  const retValue = data?.paramsData?.find((item) => item.code === code)?.nvalue;
  return retValue
}

const getParamCValue = (data, code) => {
  const retValue = data?.paramsData?.find((item) => item.code === code)?.cvalue;
  return retValue
}

// async function waitedDelay(ms) {
//   await delay(ms);
// }

const validateStepTwo = (basket) => {
  const potisk = basket.predniPotisk;
  let stepTwoComplete = true;
  if (typeof potisk.barvaPotisku === "undefined") {
    stepTwoComplete = stepTwoComplete && false;
  }
  if (potisk.barvaPotisku === "undefined|undefined|undefined") {
    stepTwoComplete = stepTwoComplete && false;
  }
  return stepTwoComplete;
};

const validateStepThree = (basket) => {
  const potisk = basket.predniPotisk;
  let stepThreeComplete = true;
  if (typeof potisk.dolniPismo === "undefined") {
    stepThreeComplete = stepThreeComplete && false;
  }
  return stepThreeComplete;
};

const validateStepFive = (basket) => {
  const potisk = basket.predniPotisk;
  let stepFourComplete = true;
  stepFourComplete = stepFourComplete && validateRibbons(basket)
  stepFourComplete = stepFourComplete || basket.lists.chosen
  if (basket?.items?.length > 0) {
    if (typeof potisk.dolniPismo === "undefined") {
      stepFourComplete = stepFourComplete && false;
    }
    if (typeof potisk.barvaPotisku === "undefined") {
      stepFourComplete = stepFourComplete && false;
    }
  }

  if (basket?.items?.length === 0 && !basket.ribbons.chosen && !basket.lists.chosen) {
    stepFourComplete = stepFourComplete && false;
  }
  return stepFourComplete;
};

const validateRibbons = (basket) => {
  let isValid = true
  if (basket.ribbons.chosen) {
    isValid = isValid && (basket?.ribbons?.pismo !== undefined ? true : false)

    isValid = isValid && (basket?.ribbons?.printColorCode !== undefined ? true : false)
  }
  return isValid

}

const getBasketPrice = (basket, data) => {
  const postovne = getParamNValue(data, 'POSTOVNE')
  const priplatky = getPriplatky(basket, data)
  let totalQuantity = 0
  let totalPrice = 0

  const itemPriceDetail = getItemDetails(basket, data)
  itemPriceDetail?.forEach(function (element) {
    totalPrice += element.totalPrice
    totalQuantity += element.totalCount
  })

  const ribbons = getRibbonsPrice(basket, data)
  const lists = getListsPrice(basket, data)
  totalPrice += ribbons.ribbonsPrice
  totalPrice += lists.listsPrice



  if (totalPrice > 0)
    totalPrice += postovne

  const retData = { totalPrice: totalPrice, totalQuantity: totalQuantity, ribbons: ribbons, lists: lists, priplatky: priplatky, postovne: postovne, itemPriceDetail: itemPriceDetail }

  return retData;
};

const getPriplatky = (basket, data) => {
  const priplatky = [];
  let priplatkyCelkem = 0
  let priplatkyRukavCelkem = 0
  let priplatkyLevyRukavText = 0
  let priplatkyPravyRukavText = 0
  const sleevePrintPrice = getParamNValue(data, 'NICKNAMES')
  let priplatkyLeftSleeveNicks = 0
  let priplatkyRightSleeveNicks = 0

  if (basket?.predniPotisk?.predniPotiskVelikostA2) {
    const priplatekValue = getParamNValue(data, 'A2FRONT')
    priplatky.push({ text: `Příplatek za velikost A2 přední potisk: ${priplatekValue},00 Kč / ks`, value: parseInt(priplatekValue), sleeve: false, order: 20, nicks: false })
  }
  if (basket?.zadniPotisk?.zadniPotiskVelikostA2) {
    const priplatekValue = getParamNValue(data, 'A2BACK')
    priplatky.push({ text: `Příplatek za velikost A2 zadní potisk: ${priplatekValue},00 Kč / ks`, value: parseInt(priplatekValue), sleeve: false, order: 40, nicks: false })
  }
  // additional costs for sleeve print
  if (basket?.sleeves?.leftSleeveNickNames) {
    priplatky.push({ text: `Příplatek za přezdívky - levý rukáv: ${sleevePrintPrice},00 Kč / ks`, value: sleevePrintPrice, sleeve: true, left: true, order: 50, nicks: true })
    priplatkyRukavCelkem += sleevePrintPrice
    priplatkyLeftSleeveNicks += sleevePrintPrice
  }
  if (basket?.sleeves?.rightSleeveNickNames) {
    priplatky.push({ text: `Příplatek za přezdívky - pravý rukáv: ${sleevePrintPrice},00 Kč / ks`, value: sleevePrintPrice, sleeve: true, right: true, order: 70, nicks: true })
    priplatkyRukavCelkem += sleevePrintPrice
    priplatkyRightSleeveNicks += sleevePrintPrice
  }

  if (basket?.sleeves?.leftSleeveText && !basket?.sleeves?.leftSleeveNickNames) {
    priplatky.push({ text: `Příplatek za potisk - levý rukáv: ${sleevePrintPrice},00 Kč / ks`, value: sleevePrintPrice, sleeve: true, left: true, order: 90, nicks: false, extra: true })
    priplatkyRukavCelkem += 60
    priplatkyLevyRukavText = 60
  }

  if (basket?.sleeves?.rightSleeveText && !basket?.sleeves?.rightSleeveNickNames) {
    priplatky.push({ text: `Příplatek za potisk - pravý rukáv: ${sleevePrintPrice},00 Kč / ks`, value: sleevePrintPrice, sleeve: true, right: true, order: 100, nicks: false, extra: true })
    priplatkyRukavCelkem += 60
    priplatkyPravyRukavText = 60
  }


  const barvaId = basket?.predniPotisk?.barvaPotisku?.split("|")[2];
  const foundBarvaCena = data?.barvaPotiskuData.find((barva) => parseInt(barva.id) === parseInt(barvaId));
  if (foundBarvaCena !== undefined) {
    let barvaCenaFront = foundBarvaCena?.cena
    let barvaCenaBack = foundBarvaCena?.cena

    // additional costs for sleeve print in extra color
    if (basket.sleeves.leftSleeveNickNames) {
      priplatky.push({ text: `Příplatek za přezdívky - levý rukáv (příplatková barva): ${barvaCenaFront},00 Kč / ks`, value: barvaCenaFront, sleeve: true, left: true, order: 51, nicks: true })
      priplatkyRukavCelkem += barvaCenaFront
      priplatkyLeftSleeveNicks += barvaCenaFront
    }
    if (basket.sleeves.rightSleeveNickNames) {
      priplatky.push({ text: `Příplatek za přezdívky - pravý rukáv (příplatková barva): ${barvaCenaFront},00 Kč / ks`, value: barvaCenaFront, sleeve: true, right: true, order: 71, nicks: true })
      priplatkyRukavCelkem += barvaCenaFront
      priplatkyRightSleeveNicks += barvaCenaFront
    }


    if (basket?.sleeves?.leftSleeveText && !basket?.sleeves?.leftSleeveNickNames) {
      priplatky.push({ text: `Příplatek za potisk - levý rukáv: (příplatková barva): ${barvaCenaFront},00 Kč / ks`, value: barvaCenaFront, sleeve: true, left: true, order: 91, nicks: false, extra: true })
      priplatkyRukavCelkem += barvaCenaFront
    }

    if (basket?.sleeves?.rightSleeveText && !basket?.sleeves?.rightSleeveNickNames) {
      priplatky.push({ text: `Příplatek za potisk - pravý rukáv: (příplatková barva): ${barvaCenaFront},00 Kč / ks`, value: barvaCenaFront, sleeve: true, right: true, order: 101, nicks: false, extra: true })
      priplatkyRukavCelkem += barvaCenaFront
    }

    let a2ClauseFront = ""
    let a2ClauseBack = ""
    if (basket.zadniPotisk.zadniPotiskVelikostA2) {
      a2ClauseBack = "A2"
    }
    if (basket?.zadniPotisk?.tvarSeznamuJmen !== undefined && basket?.zadniPotisk?.tvarSeznamuJmen !== "tvarSeznamuJmen|0|0" && !basket?.zadniPotisk?.vlastniObrazekSet) {
      priplatky.push({ text: `Příplatek za barvu zadního potisku ${a2ClauseBack} : ${barvaCenaBack},00 Kč / ks`, value: parseInt(barvaCenaBack), sleeve: false, order: 30, nicks: false, extra: true })
    }
    if(basket?.zadniPotisk?.vlastniObrazekSet) {
      priplatky.push({ text: `Příplatek za barvu zadního potisku ${a2ClauseBack} : ${barvaCenaBack},00 Kč / ks`, value: parseInt(barvaCenaBack), sleeve: false, order: 30, nicks: false, extra: true })
    }
    priplatky.push({ text: `Příplatek za barvu předního potisku ${a2ClauseFront} : ${barvaCenaFront},00 Kč / ks`, value: parseInt(barvaCenaFront), sleeve: false, order: 10, nicks: false, extra: true })
  }

  const filteredPriplatky = priplatky.filter((item) => item.value > 0);

  const priplatkyNotSleeve = filteredPriplatky?.filter((priplatek) => priplatek.sleeve === false)?.sort((a, b) => (a.order > b.order ? 1 : -1));


  filteredPriplatky.filter((priplatek) => (priplatek.extra === true && priplatek.sleeve === false)).forEach(function (item, index) {
    priplatkyCelkem += parseInt(item.value);
  });
  const priplatkyNotSleeveExist = priplatkyNotSleeve?.length > 0;

  const priplatkySleeve = filteredPriplatky?.filter((priplatek) => priplatek.sleeve === true)?.sort((a, b) => (a.order > b.order ? 1 : -1));
  const priplatkySleeveExist = priplatkySleeve?.length > 0;

  const priplatkyExist = filteredPriplatky?.length > 0;

  return {
    priplatky: filteredPriplatky,
    priplatkyCelkem: priplatkyCelkem,
    priplatkyRukavCelkem: priplatkyRukavCelkem,
    priplatkyPravyRukavText: priplatkyPravyRukavText,
    priplatkyLevyRukavText: priplatkyLevyRukavText,
    priplatkyNotSleeve: priplatkyNotSleeve,
    priplatkyNotSleeveExist: priplatkyNotSleeveExist,
    priplatkySleeve: priplatkySleeve,
    priplatkySleeveExist: priplatkySleeveExist,
    priplatkyExist: priplatkyExist,
    priplatkyLeftSleeveNicks: priplatkyLeftSleeveNicks,
    priplatkyRightSleeveNicks: priplatkyRightSleeveNicks
  };
};

const getListsPrice = (basket, data) => {
  let listsPrice = 0;
  let listsExist = false;
  let listsQuantity = 0;
  let listsItemPrice = getParamNValue(data, 'LIST_NO_TEXTIL')
  if (basket?.lists?.chosen) {
    const itemsCount = basket?.items?.length;
    if (itemsCount > 0) {
      listsItemPrice = getParamNValue(data, 'LIST_TEXTIL')
    }
    listsPrice = basket.lists.quantity * listsItemPrice;
    listsQuantity = basket.lists.quantity;
    listsExist = true;
  }
  return { listsPrice: listsPrice, listsQuantity: listsQuantity, listsExist: listsExist, listsItemPrice: listsItemPrice };
};

const getRibbonsPrice = (basket, data) => {
  try {
    let ribbonsPrice = 0;
    let ribbonsExists = false;
    let ribbonsQuantity = 0;
    let ribbonItemPrice = getParamNValue(data, 'RIBBON_NO_TEXTIL')
    let ribbonsNickPrice = 0;
    let ribbonsNickItemPrice = getParamNValue(data, 'RIBBON_NICKNAMES')
    let nicks = Object.values(basket?.ribbons?.nicks)
    const validItems = nicks.filter((e) => e?.length > 0);
    nicks = validItems;
    let ribbonsNicksSelected = basket?.ribbons?.addNicks
    let ribbonsNicksSize = (ribbonsNicksSelected === true) ? nicks?.length : 0;
    const ribbonPrintColorPrice = basket?.ribbons?.printColorPrice

    if (basket?.ribbons?.chosen) {
      const itemsCount = basket?.items?.length;
      if (itemsCount > 0) {
        ribbonItemPrice = getParamNValue(data, 'RIBBON_TEXTIL')
      }
      ribbonsPrice = parseInt(basket.ribbons.quantity - ribbonsNicksSize) * (parseInt(ribbonItemPrice) + parseInt(ribbonPrintColorPrice));
      ribbonsNickPrice = parseInt(ribbonsNicksSize) * (parseInt(ribbonItemPrice) + parseInt(ribbonPrintColorPrice) + parseInt(ribbonsNickItemPrice));
      ribbonsQuantity = basket.ribbons.quantity;
      ribbonsExists = true;
    }
    const retData = { ribbonsPrice: ribbonsPrice + ribbonsNickPrice, ribbonsQuantity: ribbonsQuantity, ribbonsExists: ribbonsExists, ribbonItemPrice: ribbonItemPrice, ribbonPrintColorPrice: ribbonPrintColorPrice, ribbonsNicks: ribbonsNicksSelected, ribbonsNicksSize: ribbonsNicksSize };
    return retData
  } catch (error) {
    return {}
  }
};

const getItemDetails = (basket, data) => {
  let itemDetails = []
  const priplatky = getPriplatky(basket, data)

  basket?.items?.forEach((oneItem) => {
    var extrasPrice = 0
    var nicksPrice = 0
    const priplatkyCelkem = priplatky?.priplatkyCelkem;
    let priplatkyRukavCelkem = 0 // priplatky?.priplatkyRukavCelkem;

    // number of assigned names or nicks to a textilia
    var mappedNamesCount = 0
    if (basket?.students?.mappedNames[oneItem.id]?.length > 0) {
      mappedNamesCount = basket?.students?.mappedNames[oneItem.id]?.length
    }

    // assume that the rest of the goods don't have a print on a sleeve
    let noSleevePrintCount = oneItem.quantity - mappedNamesCount;
    let textSleevePrintCount = 0
    // if there's a custom text on any sleeve, the surcharge for print is a fixed for every single piece
    if (priplatky.priplatkyPravyRukavText > 0 || priplatky.priplatkyLevyRukavText > 0) {
      noSleevePrintCount = 0
      textSleevePrintCount = oneItem.quantity - mappedNamesCount
    }


    priplatky.priplatky.filter((priplatek) => (priplatek.extra === true)).forEach(function (item, index) {
      extrasPrice += item.value
    })
    priplatky.priplatky.filter((priplatek) => (priplatek.nicks === true)).forEach(function (item, index) {
      nicksPrice += item.value
    })
    var priceWithNickSleevePrint = oneItem.price + extrasPrice + nicksPrice

    // let's calculate the prices. Start with no extras
    const priceWithoutSleevePrint = oneItem.price + extrasPrice;
    const priceWithTextSleevePrint = oneItem.price + extrasPrice
    const totalPriceNoSleevePrint = priceWithoutSleevePrint * noSleevePrintCount
    const totalPriceSleevePrintNicks = priceWithNickSleevePrint * mappedNamesCount
    const totalPriceSleevePrintText = priceWithTextSleevePrint * textSleevePrintCount

    const totalPrice = totalPriceNoSleevePrint + totalPriceSleevePrintNicks + totalPriceSleevePrintText;



    const ret = {
      itemId: oneItem.id,
      mappedNamesCount: mappedNamesCount,
      noSleevePrintCount: noSleevePrintCount,
      textSleevePrintCount: textSleevePrintCount,
      priceWithoutSleevePrint: priceWithoutSleevePrint,
      priceWithNickSleevePrint: priceWithNickSleevePrint,
      priceWithTextSleevePrint: priceWithTextSleevePrint,
      totalPriceNoSleevePrint: totalPriceNoSleevePrint,
      totalPriceSleevePrintNicks: totalPriceSleevePrintNicks,
      totalPriceSleevePrintText: totalPriceSleevePrintText,
      totalPrice: totalPrice,
      totalCount: parseInt(oneItem.quantity)
    }
    itemDetails.push(ret)
  })
  return itemDetails
  //         existingItem = state.basket.items.find((item) => item.id === newItem.id);

}

const saveAudit = async (basket) => {
  await fetch(`${API_BASE}/audit`, {
    method: "POST",
    mode: "cors",
    headers: JSON_HEADER,
    body: JSON.stringify({ data: basket }),
  });

}

export default {
  validateStepTwo,
  validateStepThree,
  getBasketPrice,
  getPriplatky,
  getRibbonsPrice,
  getListsPrice,
  getParamCValue,
  getParamNValue,
  validateStepFive,
  validateRibbons,
  getItemDetails,
  saveAudit
};
