import { API_BASE, JSON_HEADER } from '../constants';

 async function getAllParams () {
    const url = `${API_BASE}/params`
    const data =  await fetch(url, {
        headers: JSON_HEADER
    })
        .then(function (response) {
            return response.json();
        })
         
    return data.data;
}


async function getVersion () {
    const url = `${API_BASE}/params/version`
    const data =  await fetch(url, {
        headers: JSON_HEADER
    })
        .then(function (response) {
            return response.json();
        })
         
    return data?.nvalue;
}

 
 

export default {
    getAllParams,
    getVersion
}
