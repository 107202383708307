import React, { useEffect, useState, useCallback } from "react";
import { cartActions } from '../../store/cart-slice';
import { useDispatch, useSelector } from 'react-redux';
import PrintItem from '../UI/PrintItem';
import SeznamJmen from './SeznamJmen';
import StepExtraRequest from '../UI/StepExtraRequest';
import { useNavigate } from "react-router-dom";
import seznamJmenService from "../../services/seznam-jmen.service";
import dolniPismoService from '../../services/dolni-pismo.service';
import paramsService from "../../services/params.service";


const StepThree = (props) => {
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const [dolniPismoData, setDolniPismoData] = useState([])
    const [tvarSeznamuJmenData, setTvarSeznamuJmendata] = useState([])
    // const cartDolniPismoData = cart.data.dolniPismoData;
    // const cartTvarSeznamuJmenData = cart.data.tvarSeznamuJmenData;
    const [isValidStep, setIsValidStep] = useState(false);
    let history = useNavigate();

    const getSeznamJmenData = useCallback(async () => {
        const tvarSeznamuJmenData = await seznamJmenService.getAllItems();
        setTvarSeznamuJmendata(tvarSeznamuJmenData)

        // if (cartTvarSeznamuJmenData === undefined || cartTvarSeznamuJmenData.length === 0) {
        //     const tvarSeznamuJmenData = await seznamJmenService.getAllItems();
        //     dispatch(
        //         cartActions.setData({
        //             type: 'SEZNAM_JMEN',
        //             data: tvarSeznamuJmenData
        //         })
        //     );
        // }
    }, [])

    const getDolniPismoData = useCallback(async () => {
        const dolniPismoData = await dolniPismoService.getAllItems();
        setDolniPismoData(dolniPismoData)
    }, [])

    const setActivePage = () => {
        dispatch(
            cartActions.setActiveStep({
                step: 'THREE'
            })
        );
        if (!cart.stepThreeValid && !cart.stepThreeFirstLoad) {
            dispatch(
                cartActions.setWarning({
                    message: "Nevybrali jste druh písma pro potisk!",
                })
            );

        }
    }

    const previousStepNotComplete = () => {
        const isIncomplete = !cart.stepTwoValid
        setIsValidStep(!isIncomplete)
        return isIncomplete
    }

    const checkVersion = async () => {
        const currentVersion = await paramsService.getVersion()

        if (cart?.minimumVersion === undefined || cart?.minimumVersion !== currentVersion) {
            dispatch(cartActions.resetStore({}));
            history('/')
        }
    }

    useEffect(() => {
        if (previousStepNotComplete()) {
            history('/stepTwo')
            return
        }
        getSeznamJmenData();
        getDolniPismoData();
        setActivePage();
        checkVersion()
    }, []);

    return (
        isValidStep && <React.Fragment >

            <PrintItem
                printItemIdLabel="tvar_seznamu_jmen"
                printItemId={1}
                sliderId={31}
                itemList={tvarSeznamuJmenData}
                itemStripLabel="Tvar Seznamu Jmen"
                itemType='tvarSeznamuJmen'
                dataType='tvarSeznamuJmen'
                endPoint={'seznam-jmen'}
                itemsPerPage={4}
                oneImageWidth={142} />

            <StepExtraRequest sideType={'zadniPotisk'} />


            <PrintItem
                printItemIdLabel="dolni_pismo"
                printItemId={1}
                sliderId={32}
                itemList={dolniPismoData}
                itemStripLabel="Druh písma"
                itemType='dolniPismo'
                dataType='dolniPismo'
                endPoint={'dolni-pismo'}
                itemsPerPage={3}
                oneImageWidth={190}
                svg={true}
                noPrint="false"
            />
            <div className="sliderInfo">
                <p>
                    Druh písma se vztahuje k přednímu i zadnímu potisku.
                </p>
            </div>

            <SeznamJmen></SeznamJmen>

        </React.Fragment >
    )
}

export default StepThree