import React, { useEffect, useState, useCallback } from "react";
import genericService from "../../services/generic.service";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";

const TextilDetail = (props) => {
    const [basketPrice, setBasketPrice] = useState();
    const [itemDetails, setItemDetails] = useState();
    const dispatch = useDispatch();

    const item = props.item;
    const basket = props.basket;
    const data = props.data;
    const priplatky = basketPrice?.priplatky;

    const priplatkyExist = priplatky?.priplatkyExist;
    const priplatkyCelkem = priplatky?.priplatkyCelkem;
    const priplatkyRukavCelkem = priplatky?.priplatkyRukavCelkem;

    // const totalCount = props.totalCount;
    const activeStep = props.activeStep;

    const priplatkyNotSleeve = priplatky?.priplatkyNotSleeve;
    const priplatkyNotSleeveExist = priplatky?.priplatkyNotSleeveExist;

    const priplatkySleeve = priplatky?.priplatkySleeve;
    const priplatkySleeveExist = priplatky?.priplatkySleeveExist;

    const mappedNamesCount = itemDetails?.mappedNamesCount
    const noSleevePrintCount = itemDetails?.noSleevePrintCount;
    const isStepFive = activeStep === "FIVE";
    const isNotStepFive = !isStepFive;
    const priceWithoutSleevePrint = itemDetails?.priceWithoutSleevePrint;
    const priceWithSleevePrint = itemDetails?.priceWithSleevePrint;
    const totalPriceNoSleevePrint = itemDetails?.totalPriceNoSleevePrint;
    const totalPriceSleevePrint = itemDetails?.totalPriceSleevePrint;
    const totalPrice = itemDetails?.totalPrice;


    const getBasketPrice = () => {
        const basketPrice = genericService.getBasketPrice(basket, data);
        const itemDetails = genericService.getItemDetails(basket, data)
        const oneItem = itemDetails.filter((itm) => itm.itemId === item.id)
        setItemDetails(oneItem[0])
        setBasketPrice(basketPrice);
    };

    useEffect(() => {
        getBasketPrice();
    }, [basket]);

    return (
        <React.Fragment>
            Materiál: {item.material}
            <br />
            Gramáž: {item.gramaz}
            <br />
            <br />
            Cena za ks s jednobarevnym zakladnim potiskem:{" "}
            {new Intl.NumberFormat("cs-CS", {
                style: "currency",
                currency: "CZK",
            }).format(item.price)}{" "}
            / ks
            <br />
            {priplatkyNotSleeveExist && (
                <>
                    <br />
                    <strong>Příplatky:</strong>
                </>
            )}
            {priplatkyNotSleeve?.map((priplatek, index) => (
                <React.Fragment key={`priplatek${index}`}>
                    <br />
                    <span>{priplatek.text}</span>
                </React.Fragment>
            ))}
            {priplatkyNotSleeveExist && (
                <>
                    <br />
                    <strong>Příplatky celkem: </strong>
                    {new Intl.NumberFormat("cs-CS", {
                        style: "currency",
                        currency: "CZK",
                    }).format(priplatkyCelkem)}{" "}
                    / ks
                    <br />
                </>
            )}
            {priplatkySleeveExist && (
                <>
                    <br />
                    <strong>Příplatky za potisk rukávu:</strong>
                    {priplatkySleeve?.map((priplatek, index) => (
                        <React.Fragment key={`priplatekSleeve${index}`}>
                            <br />
                            <span>{priplatek.text}</span>
                        </React.Fragment>
                    ))}
                    {priplatkySleeveExist && (
                        <>
                            <br />
                            <strong>Příplatky za potisk rukávu celkem : </strong>
                            {new Intl.NumberFormat("cs-CS", {
                                style: "currency",
                                currency: "CZK",
                            }).format(priplatkyRukavCelkem)}{" "}
                            / ks
                            <br />
                        </>
                    )}
                </>
            )}
            <br />
            Celkem ks: {itemDetails?.totalCount}
            <br />
            {noSleevePrintCount > 0 && (
                <>
                    <br />
                    Cena za kus s příplatky {priplatkySleeveExist && <>(bez potisku rukávu)</>}:{" "}
                    {new Intl.NumberFormat("cs-CS", {
                        style: "currency",
                        currency: "CZK",
                    }).format(priceWithoutSleevePrint)}{" "}
                    / ks
                    {isStepFive && (mappedNamesCount > 0 || noSleevePrintCount > 0) && (
                        <>
                            <br />
                            Celkem ks bez potisku rukávu {noSleevePrintCount}
                            <br />
                            Cena celkem bez potisku rukávu{" "}
                            {new Intl.NumberFormat("cs-CS", {
                                style: "currency",
                                currency: "CZK",
                            }).format(totalPriceNoSleevePrint)}
                        </>
                    )}
                    <br />
                </>
            )}
            {(itemDetails?.priceWithTextSleevePrint > 0 && itemDetails?.textSleevePrintCount > 0) && (
                <>
                    <br />
                    Cena za kus (s potiskem rukávu - vlastní text):{" "}
                    {new Intl.NumberFormat("cs-CS", {
                        style: "currency",
                        currency: "CZK",
                    }).format(itemDetails?.priceWithTextSleevePrint)}{" "}
                    / ks
                    {isStepFive && itemDetails?.textSleevePrintCount > 0 && (
                        <>
                            <br />
                            Celkem ks s potiskem rukávu {itemDetails?.textSleevePrintCount}
                            <br />
                            Cena celkem s potiskem rukávu{" "}
                            {new Intl.NumberFormat("cs-CS", {
                                style: "currency",
                                currency: "CZK",
                            }).format(itemDetails?.totalPriceSleevePrintText)}
                            <br />
                        </>
                    )}
                </>
            )}
            {(itemDetails?.priceWithNickSleevePrint > 0 && itemDetails?.mappedNamesCount > 0) && (
                <>
                    <br />
                    Cena za kus (s potiskem rukávu - přezdívky):{" "}
                    {new Intl.NumberFormat("cs-CS", {
                        style: "currency",
                        currency: "CZK",
                    }).format(itemDetails?.priceWithNickSleevePrint)}{" "}
                    / ks
                    {isStepFive && itemDetails?.mappedNamesCount > 0 && (
                        <>
                            <br />
                            Celkem ks s potiskem rukávu {itemDetails?.mappedNamesCount}
                            <br />
                            Cena celkem s potiskem rukávu{" "}
                            {new Intl.NumberFormat("cs-CS", {
                                style: "currency",
                                currency: "CZK",
                            }).format(itemDetails?.totalPriceSleevePrintNicks)}
                            <br />
                        </>
                    )}
                </>
            )}
            {isNotStepFive && (
                <>
                    <br />

                    <strong id="cenaCelkem">
                        Cena za druh textilu celkem:{" "}
                        {new Intl.NumberFormat("cs-CS", {
                            style: "currency",
                            currency: "CZK",
                        }).format(priceWithoutSleevePrint * itemDetails?.totalCount)}
                    </strong>
                </>
            )}
            {isStepFive && (
                <>
                    <br />
                    <strong id="cenaCelkem">
                        Cena za druh textilu celkem:{"  "}
                        {new Intl.NumberFormat("cs-CS", {
                            style: "currency",
                            currency: "CZK",
                        }).format(totalPrice)}
                    </strong>
                </>
            )}
        </React.Fragment>
    );
};

export default TextilDetail;
