async function addressAutocomplete(searchStr) {
    const reqBody = {
        "input": searchStr,
        "languageCode": "cs",
        "includedRegionCodes": ["cz", "sk"],
        // "includeQueryPredictions": true,
        // "types": ["premise", "geocode", "postal_code"]
    }
    const JSON_HEADER = {
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': 'AIzaSyAxpdyjyFJ0jSoZ9d2Ari5WKhNOIhrp3Gw'
    }

    const retData = await fetch(`https://places.googleapis.com/v1/places:autocomplete`, {
        method: "POST",
        mode: "cors",
        headers: JSON_HEADER,
        body: JSON.stringify(reqBody)
    });
    const resData = await retData.json()
    console.log("🚀 -------------------------------------------------------------------------🚀")
    console.log("🚀 ~ file: address.service.js:18 ~ addressAutocomplete ~ resData:", resData)
    console.log("🚀 -------------------------------------------------------------------------🚀")
    return resData
}

export default {
    addressAutocomplete
}