import React, { useEffect, useState, useCallback } from "react";
import { cartActions } from "../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";
import BarvaPotisku from "../UI/BarvaPotisku";
import StepTwoService from "../../services/step-two";
import StepExtraRequest from "../UI/StepExtraRequest";
import PrintItem from "../UI/PrintItem";
import { useNavigate } from "react-router-dom";
import paramsService from "../../services/params.service";

const StepTwo = () => {
  const cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  let history = useNavigate();

  const [barvaPotisku, setBarvaPotisku] = useState([]);
  const [isValidStep, setIsValidStep] = useState(false);


  const fetchHorniNapis = useCallback(async () => {
    // if (!(cart.data.horniNapisData.length > 0)) {
    const horniNapisData = await StepTwoService.getAllHorniNapis();
    dispatch(
      cartActions.setData({
        type: "HORNI_NAPIS",
        data: horniNapisData,
      })
    );
    // }
  }, []);

  const fetchBarvaPotisku = useCallback(async () => {
    // if (cart.data.barvaPotiskuData !== undefined && cart.data.barvaPotiskuData.length > 0) {
    //   setBarvaPotisku(cart.data.barvaPotiskuData);
    // } else {
    const barvaPotiskuData = await StepTwoService.getAllBarvaPotisku();
    setBarvaPotisku(barvaPotiskuData);
    dispatch(
      cartActions.setData({
        type: "BARVA_POTISKU",
        data: barvaPotiskuData,
      })
    );
    // }
  }, []);

  const fetchObrazkyPotisku = useCallback(async () => {
    let obrazkyPotiskuData = [];
    // if (cart.data.obrazkyPotiskuData === undefined || cart.data.obrazkyPotiskuData.length === 0) {
    obrazkyPotiskuData = await StepTwoService.getAllObrazkyPotisku();
    dispatch(
      cartActions.setData({
        type: "OBRAZKY_POTISKU",
        data: obrazkyPotiskuData,
      })
    );
    // } else {
    //   obrazkyPotiskuData = cart.data.obrazkyPotiskuData;
    // }
  }, []);

  const setActivePage = () => {
    dispatch(
      cartActions.setActiveStep({
        step: "TWO",
      })
    );
    if (!cart.stepTwoValid && !cart.stepTwoFirstLoad) {
      dispatch(
        cartActions.setWarning({
          message: "Vyberte barvu potisku!",
        })
      );

    }
  };

  const previousStepNotComplete = () => {
    let isIncomplete = false
    // just one condition, chosen textil, min 10 pcs
    if (cart.totalQuantity < 10) {
      isIncomplete = isIncomplete || true
    }
    setIsValidStep(!isIncomplete)
    return isIncomplete
  }

  const checkVersion = async () => {
    const currentVersion = await paramsService.getVersion()

    if (cart?.minimumVersion === undefined || cart?.minimumVersion !== currentVersion) {
      dispatch(cartActions.resetStore({}));
    }
  }
  useEffect(() => {
    if (previousStepNotComplete()) {
      history('/')
      return
    }
    fetchHorniNapis();
    fetchObrazkyPotisku();
    fetchBarvaPotisku();
    setActivePage();
    checkVersion()
  }, []);

  const onDolniPredniNapisTextChangeHandler = (event) => {
    const value = event.target.value;
    dispatch(
      cartActions.setDolniPredniNapisText({
        value,
      })
    );
    event.stopPropagation();
  };


  return (
    isValidStep && <React.Fragment>
      <PrintItem
        printItemIdLabel="horni_napis"
        printItemId={2}
        sliderId={21}
        itemList={cart.data !== undefined ? cart.data.horniNapisData : []}
        itemStripLabel="Horní nápis"
        itemType="horniNapis"
        dataType="horniNapis"
        endPoint={"horni-potisk"}
        itemsPerPage={4}
        oneImageWidth={142}
      />
      <PrintItem
        printItemIdLabel="obrazky_potisku"
        printItemId={2}
        sliderId={22}
        itemList={cart.data.obrazekPotiskuData}
        itemStripLabel="Obrázky potisku"
        itemType="obrazekPotisku"
        dataType="obrazekPotisku"
        endPoint={"obrazek-potisk"}
        itemsPerPage={3}
        oneImageWidth={190}
      />
      <StepExtraRequest sideType={"predniPotisk"} />

      <div className="relative">
        <h2 className="strip n2">Vlastní text na přední stranu</h2>
      </div>
      <br />
      <fieldset className="simplyForm">
        <input
          type="text"
          placeholder="Vlastní text"
          className="text big"
          name="frontBottomText"
          id="bottom_text"
          onChange={onDolniPredniNapisTextChangeHandler}
          value={cart.basket.predniPotisk !== undefined ? cart.basket.predniPotisk.dolniPredniNapisText : ""}
        />
        <div id="textPreview"></div>
      </fieldset>

      <BarvaPotisku barvaPotisku={barvaPotisku} ribbons={false} />
    </React.Fragment>
  );
};

export default StepTwo;
