import React, { useEffect, useState } from "react";
import { cartActions } from "../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";
import genericService from "../../services/generic.service";
import AlertDialog from "../UI/AlertDialog";
import addressService from "../../services/address.service";

const StepFiveSide = () => {
  const marginTop10 = { marginTop: "10px" };
  const basket = useSelector((state) => state?.cart?.basket);
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.cart?.data);
  const [basketPrice, setBasketPrice] = useState();

  const onDetailsChangeHandler = (event) => {
    const idSplit = event.target.name.split("#");
    const type = idSplit[1];
    const subtype = idSplit[2];
    const value = event.target.value;
    dispatch(
      cartActions.setOrderDetails({
        type: type,
        subtype: subtype,
        value: value,
      })
    );
  };

  const onUliceChangeHandler = (event) => {
    if (event.target.value.length > 3) {
      const suggestion = addressService.addressAutocomplete(event.target.value)
      console.log("🚀 -----------------------------------------------------------------------------🚀")
      console.log("🚀 ~ file: StepFiveSide.js:32 ~ onUliceChangeHandler ~ suggestion:", suggestion)
      console.log("🚀 -----------------------------------------------------------------------------🚀")
    }
    const idSplit = event.target.name.split("#");
    const type = idSplit[1];
    const subtype = idSplit[2];
    const value = event.target.value;
    dispatch(
      cartActions.setOrderDetails({
        type: type,
        subtype: subtype,
        value: value,
      })
    );
  };




  useEffect(() => {
    const getBasketPrice = async () => {
      const basketPrice = genericService.getBasketPrice(basket, data);
      setBasketPrice(basketPrice);
    };
    getBasketPrice();
  }, [basket, data]);

  return (
    <React.Fragment>
      <aside>
        <div id="submit_form">
          <AlertDialog open={true} />
          {basket?.students?.names?.length > 0 && (
            <fieldset>
              <legend>Seznam jmen</legend>
              {basket?.students?.names.map((oneItem, index) => (
                <div id={`basketStudentName#${index + 1}`} key={`basketStudentName#${index + 1}`}>
                  {index + 1}.&nbsp;{oneItem?.name} {typeof basket?.students?.nicks[index] === "undefined" || basket.students.nicks[index] === null ? "" : `(${basket.students.nicks[index].name})`}
                </div>
              ))}
            </fieldset>
          )}

          <a name="kontakt"></a>
          <fieldset>
            <legend>Kontaktní osoba</legend>
            <div className="input text">
              <label htmlFor="basket#contact#firstname">Jméno</label>
              <input name="basket#contact#firstname" type="text" id="basket#contact#firstname" value={basket?.contact?.firstname} onChange={onDetailsChangeHandler} />
            </div>
            <div className="input text">
              <label htmlFor="basket#contact#lastname">Příjmení</label>
              <input name="basket#contact#lastname" type="text" id="basket#contact#lastname" value={basket?.contact?.lastname} onChange={onDetailsChangeHandler} />
            </div>
            <div className="input text">
              <label htmlFor="basket#contact#phone">Telefon</label>
              <input name="basket#contact#phone" type="text" id="basket#contact#phone" value={basket?.contact?.phone} onChange={onDetailsChangeHandler} />
            </div>
            <div className="input text">
              <label htmlFor="basket#contact#email">Email</label>
              <input name="basket#contact#email" type="text" id="basket#contact#email" value={basket?.contact?.email} onChange={onDetailsChangeHandler} />
            </div>
          </fieldset>
          <fieldset>
            <legend>Dodací adresa</legend>
            <div className="input text">
              <label htmlFor="company">Škola/Firma</label>
              <input id="company" type="text" name="basket#address#company" value={basket?.address?.company} onChange={onDetailsChangeHandler} onBlur={onDetailsChangeHandler} />
            </div>
            <div className="input text">
              <label htmlFor="ulice">Ulice</label>
              <input
                type="text"
                name="basket#address#street"
                id="ulice"
                value={basket?.address?.street}
                className="smartform-street-and-number"
                onChange={onUliceChangeHandler}
                onBlur={onUliceChangeHandler}
              />
            </div>
            <div className="input text">
              <label htmlFor="obec">Obec</label>
              <input id="obec" type="text" name="basket#address#city" value={basket?.address?.city} className="smartform-city" onChange={onDetailsChangeHandler} onBlur={onDetailsChangeHandler} />
            </div>
            <div className="input text">
              <label htmlFor="psc">PSČ</label>
              <input id="psc" type="text" name="basket#address#zip" value={basket?.address?.zip} className="smartform-zip" onChange={onDetailsChangeHandler} onBlur={onDetailsChangeHandler} />
            </div>
          </fieldset>
          <fieldset>
            <legend>Poznámka</legend>
            Pokud jste na něco nenašli kolonku nebo chcete něco změnit či oznámit, napište to zde:
            <br />
            <div className="input textarea">
              <textarea name="basket#note#note" cols="30" rows="6" id="basket#note#note" value={basket?.note?.note} onChange={onDetailsChangeHandler}></textarea>
            </div>
            <br />
            Zásilku zašleme na dobírku službou DPD/PPL.Cena poštovného a balného je <strong>200Kč.</strong>
            <div style={marginTop10}>
              <h3>
                <strong>
                  Celkem k úhradě:{" "}
                  <span id="totalProductPrice">
                    {" "}
                    {new Intl.NumberFormat("cs-CS", {
                      style: "currency",
                      currency: "CZK",
                      minimumFractionDigits: 0,
                    }).format(basketPrice?.totalPrice)}
                  </span>
                </strong>
              </h3>
            </div>
          </fieldset>
        </div>
      </aside>
    </React.Fragment>
  );
};

export default StepFiveSide;
